import image from "../../assets/image";
import {Link} from "react-router-dom";
import Modal from 'react-modal';
import {useEffect, useState} from "react";
import QrReader from 'react-qr-scanner';
import jsQR from 'jsqr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBolt, faImage, faRotate} from '@fortawesome/free-solid-svg-icons';
import Login from "../login";
import {Toast} from "../../uiliti";
import {apis, request_url} from "../../apis";
function Home() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [exchangeGifts, setExchangGifts] = useState(false);
    const [result, setResult] = useState('');
    const [scanning, setScanning] = useState(false);
    const [facingMode, setFacingMode] = useState('environment');
    const [torchEnabled, setTorchEnabled] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [phone, setPhone] = useState('');
    const [dataSearch, setDataSearch] = useState([])

    // useEffect(() => {
    //     const phone=sessionStorage.getItem('phone');
    //     if (phone) {
    //         setPhone(phone);
    //         setIsLogin(false);
    //     }
    // }, [phone]);


    const handleScan = data => {
        if (data) {
            setResult(data?.text);
            console.log(data?.text)
            setExchangGifts(true);
            setScanning(false);
        }
    }

    const handleError = err => {
        console.error(err);
    }
    const handleLink= () => {
        window.location.href='https://zalo.me/s/4591830794596539396/'
    }

    const startScan = () => {
       if(!phone){
           setIsLogin(true);
       }
       setScanning(true)
    }

    function closeModal() {
        setIsOpen(false);
    }

    const openModal = () => {
        // setIsOpen(true);
        getDataSearch();
    }

    const getDataSearch = async () => {

        if (/^\d{10}$/.test(phone)) {
            console.log(phone)
            const data= new FormData();
            data.append('phone', phone);

            const res=await request_url({
                url: apis.getPointClient(),
                method: 'POST',
                data: data,

            })
            if (res?.status){
                console.log(res?.data)
                setIsOpen(true);
                setDataSearch(res?.data);

            }else{
                Toast({type: 'warning', message: res?.msg})
            }
            console.log(res)
        } else {
            Toast({type: 'error', message: 'Số điện thoại không hợp lệ'})
        }


    }


    const handleSearch = async () => {


    }



    const toggleTorch = () => {
        setTorchEnabled(prevState => !prevState);
    };
    const switchCamera = () => {
        setFacingMode(prevMode => (prevMode === 'environment' ? 'user' : 'environment'));
    }
    const handleFileChange = event => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function (event) {
                const image = new Image();
                image.onload = function () {
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.width = image.width;
                    canvas.height = image.height;
                    context.drawImage(image, 0, 0);
                    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                    const code = jsQR(imageData.data, imageData.width, imageData.height);
                    if (code) {
                        setResult(code.data);
                        setScanning(false)
                        setExchangGifts(true);
                        console.log(code.data);
                    } else {
                        setResult('Không tìm thấy mã QR trong ảnh.');
                    }
                };
                image.src = event.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    Modal.setAppElement('#root');
    return (
        <div className={"wrapp-home pb-10 relative"}>

            <div className="head-home">
                <div className="px-2 md:px-10 py-2 text-white flex md:justify-between items-center">
                    <div>
                        <img src={image.logo} alt="" className='md:w-[106px] w-[51px] h-[36px] md:h-[83px]'/>
                    </div>
                    <div className="ms-3 text[18px] font-maitree font-medium  md:text-4xl ">
                        Kênh tích điểm đồng thương hiệu
                    </div>
                    <div className="flex-none hidden md:block">
                        <img src={image.head_2} alt="" className={'w-[214px] h-[59px]'}/>
                    </div>

                </div>

            </div>
            <div className="bg-white  py-3 w-full">
                <div
                    className="flex justify-between px-2 md:px-10 border-b-2 border-[#E5E5E5] py-3 w-full text-[12px] md:text-[15px]">
                    <div className="font-inter text-[#A22BA4] font-medium ">
                        <Link to={'https://zalo.me/s/4591830794596539396/'} className={''}>Tích điểm trên Zalo *Click
                            ngay*</Link>
                    </div>
                    <div className="flex items-center head-text text-sm font-maitree">
                        <span className={'d-xl'}><img src={image.icon_map} alt=""/></span>
                        <span className={'ms-2 d-xl'}>Các thương hiệu Hỗ trợ: Lineabon ; BESTICAL</span>
                        <span className={'ms-3'}><img src={image.icon_phone} alt=""/></span>
                        <span className={'ms-2 me-3'}>0986060688</span>
                    </div>

                </div>

            </div>
            <div className="main">
                <div className="container text-center m-auto py-10">
                    <div className="block text-center d-xl">
                        <div
                            className="font-paytone text-[#C207F0] text-[32px]  border-[4px] border-[#6100DB] rounded-[20px] inline-block px-[50px] relative">Tích
                            điểm & Tra cứu
                            <div
                                className="absolute size-[20px] bg-[#783FA6] top-[50%] left-0 translate-y-[-50%] translate-x-[-50%] rounded-full"></div>
                            <div
                                className="absolute size-[20px] bg-[#783FA6] top-[50%] right-0 translate-y-[-50%] translate-x-[50%] rounded-full"></div>
                        </div>
                    </div>
                    <div className="mt-5 ">
                        <div className="flex justify-between gap-10 md:flex-row flex-col px-10 md:px-0 ">
                            <div
                                className="w-full md:w-[50%]  bg-white rounded-xl font-maitree py-3 md:py-14 md:h-[468px] flex flex-col justify-between px-3 md-px-10 gap-3 ">
                                <div className="">
                                    <div className="text-[15px] md:text-[35px] font-semibold text-[#9958CF]">Tra cứu
                                        thông tin điểm
                                    </div>
                                    <div className="text-sm text-[#9958CF] hidden md:block">Ba mẹ đã tích điểm của các
                                        thương hiệu
                                        Lineabon d3k2, Bestical chỉ cần nhập số điện thoại
                                        đã tích điểm để hiển thị các thông tin tích điểm.
                                    </div>
                                </div>
                                <div className="">
                                    <input type="text" className="w-full s-input h-[57px] p-3"
                                           placeholder={'Nhập số điện thoại ( vd: 0986060688 )'}
                                    onChange={(e)=>setPhone(e.target.value)}/>
                                </div>
                                <div className="">
                                    <div
                                        className='text-[11px] md:text-[35px] font-semibold text-white rounded-[10px]  bg-[#CB3ECD] inline-block py-3 px-[20px] md:px-[50px]'
                                        onClick={openModal}>
                                        Tra cứu điểm
                                    </div>
                                </div>

                            </div>
                            <div
                                className="w-full md:w-[50%]  bg-white rounded-xl font-maitree py-3 md:py-14 md:h-[468px] flex flex-col justify-between px-3 md-px-10 gap-3 ">
                                <div className="">
                                    <div className="text-[15px] md:text-[35px] font-semibold text-[#9958CF]">Tích điểm
                                    </div>
                                    <div className="flex w-full gap-2">
                                        <div className="text-sm text-[#9958CF] text-wrap flex-grow w-[50px] md:w-auto">
                                            Người dùng sẽ cào mã QR trên Tem
                                            chống giả, sau đó click vào “Quét QR
                                            code" để mở Camera và tích điểm
                                        </div>
                                        <div className="text-center  justify-center w-[100px] block md:hidden">
                                            <img src={image.qr_code} className='w-full object-cover' alt={''}/>
                                        </div>
                                    </div>
                                    <div className="text-center  justify-center hidden md:flex">
                                        <img src={image.qr_code} className={'w-[27%] object-contain'} alt={''}/>
                                    </div>
                                </div>
                                <div className="">
                                    <div
                                        className='text-[11px] md:text-[35px] font-semibold text-white rounded-[10px]  bg-[#CB3ECD] inline-block py-3 px-[20px] md:px-[50px]'
                                        onClick={handleLink}>
                                        Quét QR Code
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="text-center mt-5 font-inter text-[15px] font-light italic text-[#A22BA4]">
                            <div className="">
                                Quý khách hàng có thể tích điểm và tra cứu đổi quà dễ dàng hơn bằng việc thực hiện trên
                                zalo.
                                <br></br>
                                Tên mini app “ Tích điểm Revo "
                            </div>


                        </div>
                        <div className="block text-center mt-5 ">
                            <div
                                className="font-paytone text-[#C207F0] text-16px md:text-[32px]  border-[4px] border-[#6100DB] rounded-[20px] inline-block px-[50px] py-2 relative">
                                Quà tặng tích điểm
                                <div
                                    className="absolute size-[20px] bg-[#783FA6] top-[50%] left-0 translate-y-[-50%] translate-x-[-50%] rounded-full"></div>
                                <div
                                    className="absolute size-[20px] bg-[#783FA6] top-[50%] right-0 translate-y-[-50%] translate-x-[50%] rounded-full"></div>
                            </div>
                        </div>

                        <div
                            className="pros grid grid-cols-2  md:grid-cols-4 grid-flow-row gap-[40px] md:gap-[40px] mt-5 ">
                            <div className="bg-white rounded-[15px] p-5 pb-14 text-[#C207F0]">
                                <div className="rounded-[7px] border-[1px] border-[#9A8B8B] p-2">
                                    <img src={image.pro_1} alt="" className={'w-full object-contain h-[168px]'}/>
                                    <div className="font-paytone text-[24px] ">Lineabone</div>
                                </div>
                                <div className="mt-5 font-pattaya text-[22px] italic font-normal">
                                    5 điểm = Bài chải điện
                                </div>
                            </div>
                            <div className="bg-white rounded-[15px] p-5 pb-14 text-[#C9AE00]">
                                <div className="rounded-[7px] border-[1px] border-[#9A8B8B] p-2">
                                    <img src={image.pro_2} alt="" className={'w-full object-contain h-[168px]'}/>
                                    <div className="font-paytone  text-[24px] ">Bestical</div>
                                </div>
                                <div className="mt-5 font-pattaya text-[22px] italic font-normal">
                                    5 điểm = Bài chải điện
                                </div>
                            </div>
                            <div className="bg-white rounded-[15px] p-5 pb-14 text-[#E51A24]">
                                <div className="rounded-[7px] border-[1px] border-[#9A8B8B] p-2">
                                    <img src={image.pro_3} alt="" className={'w-full object-contain h-[168px]'}/>
                                    <div className="font-paytone text-[24px] ">Calci MK7 MAX</div>
                                </div>
                                <div className="mt-5 font-pattaya text-[22px] italic font-normal">
                                    9 điểm = Calci MK7 MAX
                                </div>
                            </div>
                            <div className="bg-white rounded-[15px] p-5 pb-14 text-[#BEA67C]">
                                <div className="rounded-[7px] border-[1px] border-[#9A8B8B]">
                                   <div className="p-2">
                                       <img src={image.pro_4} alt="" className={'w-full object-contain h-[168px]'}/>
                                   </div>
                                    <div className="font-paytone text-[24px] ">HARTUS Immunity</div>
                                </div>
                                <div className="mt-5 font-pattaya text-[22px] italic font-normal">
                                    5 điểm = bài chải điện
                                </div>
                            </div>
                        </div>

                        <div className="mt-5 md:hidden">
                            <div className="px-2 text-center font-inter text-[15px] font-light italic text-[#A22BA4]">
                                Quý khách thực hiện tích điểm đủ điểm để đổi quả,
                                Khách hàng đổi quà click vào từng phần quà để sang
                                mini app đổi quà.
                            </div>
                            <div className="mt-3">
                                <img src={image.mb_bg_footer} alt="" className={'w-full'}/>
                            </div>
                        </div>


                    </div>
                </div>


            </div>
            <div className="footor p-2 d-xl ">
                <div className="flex justify-between items-center">
                    <div className="">
                        <img src={image.pro_1} className={'max-w-[258px] h-[243px] object-contain'} alt={''}/>
                    </div>
                    <div className="">
                        <img src={image.pro_2} className={'max-w-[258px]  h-[243px] object-contain'} alt={''}/>
                    </div>
                    <div className="">
                        <img src={image.pro_3} className={'max-w-[258px]  h-[243px]  object-contain'} alt={''}/>
                    </div>

                    <div className="" onClick={() => {
                        var urlToShare = window.location.href;

                        var facebookAppUrl = 'fb://share?url=' + encodeURIComponent(urlToShare);

                        var fallbackWebUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(urlToShare);

                        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

                        if (isMobile && typeof window.open(facebookAppUrl) !== 'undefined') {
                            window.open(facebookAppUrl);
                        } else {
                            window.open(fallbackWebUrl, '_blank', 'width=600,height=400');
                        }
                    }}>
                        <img src={image.footer_share} className={'max-w-[376px]  h-[156px]  object-contain'} alt={''}/>

                    </div>

                </div>

            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={
                    {
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',

                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            border: 'none',
                            padding: '0',


                        },

                    }
                }
            >

                <div className='bg-white opacity-100 shadow  shadow-black  w-[356px] p-2 rounded-xl '>
                    {
                        dataSearch?.map((item,index)=>(


                            <div className="flex items-center gap-4 mb-3 border-b-2" key={index}>
                                <div className="">
                                    {
                                        item?.code ==='CALMA'? (<img src={image.pro_3} className={'w-[65px] h-[70px] rounded-xl'} alt=""/>):''

                                    }
                                    {
                                        item?.code ==='LIBO'? (<img src={image.pro_1} className={'w-[65px] h-[70px] rounded-xl'} alt=""/>):''

                                    }
                                    {
                                        item?.code ==='BTCL'? (<img src={image.pro_2} className={'w-[65px] h-[70px] rounded-xl'} alt=""/>):''

                                    }
                                    {
                                        item?.code ==='HATUS_IMMU'? (<img src={image.pro_4} className={'w-[65px] h-[70px] rounded-xl'} alt=""/>):''

                                    }


                                </div>
                                <div className="">
                                    <div className="text-sm font-maitree font-semibold text-[#28293D]">
                                        Điểm đã tích của {item?.name}
                                    </div>
                                    <div className="text-[19px]  font-nunito font-semibold text-[#E50404]">{item?.point} ĐIỂM</div>
                                </div>
                            </div>
                        ))
                    }

                    <div className="flex justify-center">
                        <div
                            className="py-1 w-[107px] border-[1px] border-[#0CA29C] rounded-[3px]  text-white text-[14px] font-semibold bg-[#CB3ECD] text-center"
                            onClick={handleLink}>Đổi
                            điểm
                        </div>

                    </div>

                </div>

            </Modal>


            <Modal
                isOpen={exchangeGifts}
                onRequestClose={() => {
                    setExchangGifts(false)
                }}
                contentLabel="Change Point"
                style={
                    {
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',

                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            border: 'none',
                            padding: '0',


                        },

                    }
                }
            >

                <div className='bg-white opacity-100 shadow  shadow-black  w-[356px]  rounded-[20px] '>
                    <div className="text-center py-2 border-b-2">
                        <div className="font-Montserrat font-semibold text-[#28293D] text-[17px   ]">Thông báo</div>
                    </div>
                    <div className="py-4 px-16 text-[14px] font-normal text-[#28293D]">
                        <div className="text-center">
                            Bạn đã tích thành công <strong>2</strong> điểm Lineabon. Vui lòng tích thêm điểm để đổi
                            những phần quà giá
                            trị của nhãn hàng.
                        </div>
                        <div className="text-center mt-3">
                            Điểm hiện tại của bạn là Lineabone:
                            <br/>
                            <strong>32</strong> ĐIỂM
                        </div>
                        <div className="flex justify-center mt-5">
                            <div
                                className="py-1 w-[107px] border-[1px] border-[#0CA29C] rounded-[3px]  text-white text-[14px] font-semibold bg-[#CB3ECD] text-center"
                            onClick={handleLink}>Đổi
                                điểm
                            </div>

                        </div>
                    </div>
                </div>

            </Modal>
            {
                scanning?(  <div className={'fixed top-0 left-0 bg-[#D9D9D9] bg-opacity-75  w-[100vw] h-[100vh]'}>

                    <div className="flex   justify-center  h-[100vh] w-full mt-20">
                        <div className="flex flex-col">
                            <div className={'flex  items-center justify-evenly gap-4'}>
                                <div className={'text-center'}>
                                    <label htmlFor={'image'}>
                                        <FontAwesomeIcon icon={faImage} className={'text-[32px]'} />

                                        <div className={'fa-sm mt-2'}>Hình ảnh</div>
                                    </label>
                                    <input type="file" id={'image'} accept="image/*" onChange={handleFileChange}  className={'hidden'}/>
                                </div>
                                <div className={'text-center'}>
                                    <label onClick={switchCamera}>
                                        <FontAwesomeIcon icon={ faRotate} className={'text-[32px]' }/>
                                        <div className={'fa-sm mt-2'}>Máy ảnh</div>
                                    </label>
                                </div>
                                <div className={'text-center'}>
                                    <label onClick={toggleTorch}>
                                        <FontAwesomeIcon icon={faBolt}  className={'text-[32px]' }/>
                                        <div className={'fa-sm mt-2'}>Đèn pin</div>
                                    </label>
                                </div>

                            </div>
                            <QrReader
                                delay={300}
                                onError={handleError}
                                onScan={handleScan}
                                facingMode={facingMode}
                                torch={torchEnabled ? 'torch' : undefined}
                                style={{
                                    width: '400px'
                                    , aspectRatio: '1/1',
                                    borderRadius: '10px'

                                    , zIndex: 999999999999999999999999
                                }}
                            />
                            <div className={'mt-auto mb-[150px] text-center'}>
                                <div
                                    className="py-1 w-[107px] border-[1px] border-[#0CA29C] rounded-[3px]  text-white text-[14px] font-semibold bg-[#CB3ECD] text-center inline-block"
                                onClick={()=>setScanning(false)}>
                                   Đóng
                                </div>                            </div>
                        </div>
                    </div>

                </div>):('')

            }
            {
                isLogin?(
                    <Login onSubmit={(e)=>setIsLogin(false)}/>
                ):('')
            }



        </div>
    )
        ;

}

export default Home;