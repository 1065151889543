import logo from './logo_revo.png';
import head_2 from './head-2.png';
import icon_map from './icon_map.svg';
import  icon_phone from './icon_phone.svg';
import  bg_main from './bg_main.png';
import  qr_code from './qr_code.png';
import  product from './product.png';
import  bg_b from './bg_bottom.png';
import  pro_1 from './product_1.png';
import  pro_2 from './product_2.png';
import  pro_3 from './product_3.png';
import  pro_4 from './pro_4.png';
import  footer_share from './footer_share.png';
import  mb_bg_footer from './mb_bg_footer.png';
const images={
    logo,head_2,icon_map,icon_phone,bg_main,qr_code,product,bg_b,
    pro_1,pro_2,pro_3,footer_share,mb_bg_footer,pro_4

}
export default  images;