
import Home from "../page/home";
const path = {
    home: '/',


}
const routes = [
    {
        path: path.home,
        component: Home,
    }

];


const route_in = [
    {
        path: '*',
        name:'DefaultLayout',
        component:  import('../component/defaultLayout/index'),
    },




]

export {routes, path, route_in};
