import {useState} from "react";
import {Toast} from "../uiliti";

function Login({onSubmit}) {
    const [phone, setPhone] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (/^\d{10}$/.test(phone)) {
            sessionStorage.setItem('phone', phone);
            onSubmit(phone)
        } else {
            Toast({type: 'error', message: 'Số điện thoại không hơp lệ!'})
        }
    }

    return (
        <div className={'fixed top-0 left-0 bg-[#D9D9D9] h-[100vh] w-full bg-opacity-80'}>
            <div className="flex justify-center items-center h-full w-full">
                <form className={'w-full px-5'} onSubmit={handleSubmit}>
                    <div className={'bg-white w-full text-center py-5 rounded-xl px-3'}>
                        <div className="text-xl font-semibold">Vui lòng đăng nhập</div>
                        <div className={'mt-3'}>
                            <input type={'number'}
                                   className={'w-full border-2 h-[40px] p-2 rounded-lg'}
                                   name={'phone'} placeholder={'Vui lòng nhập số'}
                                   onChange={(e) => setPhone(e.target.value)}/>
                        </div>
                        <div className={'text-center mt-3'}>
                            <button className={'px-10 py-2 bg-[#CB3ECD] text-sm font-semibold rounded text-white'}>Đăng
                                nhập
                            </button>
                        </div>
                    </div>

                </form>
            </div>

        </div>
    )

}

export default Login;