import axios from "axios";

export const  baseUrl='https://temrevo.appdrbaby.com/api/';

const apis = {
    getPointClient: () => baseUrl+'points_code/client',
    getPointCustomer: (data) => baseUrl+'points_code/client',
    getPointClientActive: (data) => baseUrl+'points_code/client',

}
const token='hAmjgeyWHwCWhawwtpuqsBzTO4QOQix1M3y76ZmJVdRlZ7grQV0AbJqXyTbB';
const request_url = async ({url, method, data}) => {
    try {
        const headers = {
            "Content-Type": "application/json",
            "token": token, // Assuming token is provided
        };

        const config = {method, url, data, headers};
        const res = await axios(config);
        return res.data;

    } catch (error) {
        console.log(error);
    }
};
export {request_url,apis}