import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routes} from "./route";
import React from "react";

import './css/main.css'

function App() {
  return (
      <div>
        <div className="wrapper-app ">
          <Router> {/* Wrap your App component with Router */}
            <Routes>
              {
                routes?.map((route, index) => (
                    <Route key={index} path={'/'}   element={<route.component />}/>
                ))
              }
            </Routes>
          </Router>
        </div>
      </div>
  );
}

export default App;
